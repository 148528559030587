import React from "react";
import styled from "styled-components";

export const Magneta = styled.span`
	display: inline-block;
	font-family: Magneta, sans-serif;
`;

const Kellerkind = () => <Magneta>kellerkind</Magneta>;
export default Kellerkind;
